
import { Component, Vue } from 'vue-property-decorator'
import { TypesList } from '@/types/assetScheduling'
import AddTypes from '@/views/assetScheduling/setting/AddTypes.vue'

@Component({
  name: 'Types',
  components: { AddTypes }
})
export default class Types extends Vue {
  private id = ''
  private tableData: TypesList[] =[]
  private info= {
    assetTypeNameCode: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private showDialog = false
  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.assetScheduling.selectAssetTypeByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  onAdd () {
    this.showDialog = true
  }

  onEdit (id: string) {
    this.id = id
    this.showDialog = true
  }

  success () {
    this.getData()
    this.id = ''
  }

  onUse (assetType: string) {
    this.$axios.post(this.$apis.assetScheduling.updateAssetTypeStatus, { assetType }).then(() => {
      this.$message.success('设置成功')
      this.getData()
    }).finally(() => {
      this.loading = false
    })
  }

  onDelete (assetType: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.loading = true
      this.$axios.post(this.$apis.assetScheduling.deleteAssetType, { assetType }).then(() => {
        this.$message.success('删除成功')
        this.getData()
      }).finally(() => {
        this.loading = false
      })
    })
  }
}

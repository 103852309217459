import { render, staticRenderFns } from "./AddTypes.vue?vue&type=template&id=26e60a36&scoped=true"
import script from "./AddTypes.vue?vue&type=script&lang=ts"
export * from "./AddTypes.vue?vue&type=script&lang=ts"
import style0 from "./AddTypes.vue?vue&type=style&index=0&id=26e60a36&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26e60a36",
  null
  
)

export default component.exports
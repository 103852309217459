
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { TypesList, TypesTree } from '@/types/assetScheduling'
@Component
export default class AddTypes extends Vue {
  @Prop({ default: false }) private showDialog!: boolean
  @Prop({ default: '' }) private id!: string
  private formInfo: TypesList = this.formInfoBak
  private list: TypesTree[] = []
  private props = {
    children: 'childNode',
    label: 'assetTypeName',
    value: 'assetType'
  }

  get formInfoBak () {
    return {
      assetTypeCode: '',
      assetTypeName: '',
      assetUpId: '1',
      remarks: ''
    }
  }

  @Watch('showDialog')
  getShowDialog () {
    this.getList()
  }

  @Watch('id')
  getId (val: string) {
    val && this.getDetail(val)
  }

  private validtorode = (rule: any, value: string, callback: Function) => {
    const reg = /^(?![0-9]+$)(?![A-Z]+$)[0-9A-Z]{4,10}$/
    if (value && !reg.test(value)) {
      return callback(new Error('请输入4-10位数字和大写字母组合'))
    } else {
      return callback()
    }
  }

  private rules = {
    assetTypeCode: [
      { required: true, message: '请输入分类编码', trigger: ['blur', 'change'] },
      { validator: this.validtorode, trigger: ['blur', 'change'] }
    ],
    assetTypeName: [
      { required: true, message: '请输入分类名称', trigger: ['blur', 'change'] }
    ]
  }

  private submitShow = false

  // 查询二级类型
  getList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetTypeByList, {
      assetTypeExist: this.id || '',
      level: 1
    }).then(res => {
      this.list = res || []
    })
  }

  getDetail (val: string) {
    this.$axios.get(this.$apis.assetScheduling.selectAssetTypeByAssetType, { assetType: val }).then(res => {
      this.formInfo = res || []
    })
  }

  changeType (val: string) {
    this.formInfo.assetUpId = val || '1'
  }

  onSubmit () {
    (this.$refs.formInfo as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.id ? this.$apis.assetScheduling.updateAssetType : this.$apis.assetScheduling.insertAssetType, this.formInfo).then(() => {
          this.$message.success('保存成功')
          this.closeDialog()
          this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  closeDialog () {
    this.formInfo = this.formInfoBak
    this.$nextTick(() => {
      (this.$refs.formInfo as ElForm).resetFields()
    })
    this.$emit('update:showDialog', false)
    this.$emit('success')
  }
}
